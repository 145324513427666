var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{staticClass:"seccao header-fixo",attrs:{"id":"topo"}},[_c('Header',{attrs:{"title":_vm.category_data.area_name,"breadcrums":_vm.breadcrums,"cartItems":_vm.cartItems}}),_c('div',{staticClass:"nav-fixed"},[_c('nav',[_c('div',{staticClass:"menu-container swiper-container"},[_c('div',{staticClass:"menu swiper-wrapper"},_vm._l((_vm.menu),function(item){return _c('div',{key:'/categoria/' + item.id + '-' + item.name,staticClass:"swiper-slide"},[_c('div',{class:[
                  _vm.category_id === item.id ? 'active' : '',
                  'menu-item' ]},[_c('router-link',{key:'/categoria/' + item.id + '-' + item.name,staticClass:"links",attrs:{"to":{
                    path:
                      '/categoria/' +
                      item.id +
                      '-' +
                      item.name.replaceAll(' ', '-').toLowerCase(),
                  }}},[_vm._v(_vm._s(item.name))])],1)])}),0)]),_c('div',{staticClass:"nav-swiper-button-next"}),_c('div',{staticClass:"nav-swiper-button-prev"})])]),_c('main',{staticClass:"altura-auto"},[_c('div',{staticClass:"main-inner-wrapper"},[_c('div',{staticClass:"main-coluna-conteudo"},[_c('div',{staticClass:"main-coluna-conteudo-inner-wrapper"},[_c('h2',{staticClass:"title-with-strap"},[_vm._v(_vm._s(_vm.category_data.name))]),_c('h3',{staticClass:"h1"},[_vm._v(_vm._s(_vm.category_data.title))]),_c('div',{staticClass:"font-size-24 font-weight-500"},[_vm._v(" "+_vm._s(_vm.category_data.description)+" ")])])]),_c('div',{staticClass:"main-coluna-imagens"},[_c('div',{staticClass:"imagens-wrapper swiper-wrapper"},[(_vm.category_data.video != null)?_c('div',{staticClass:"swiper-slide video-container"},[_c('video',{staticClass:"prod-video",attrs:{"autoplay":"","loop":"","muted":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":'/assets/fotos_catalogo/products/' + _vm.category_data.video,"type":"video/mp4"}}),_vm._v(" Your browser does not support the video tag. ")])]):_vm._e(),(_vm.category_data.img_1 != null)?_c('div',{staticClass:"swiper-slide",style:({
                'background-image':
                  'url(/assets/fotos_catalogo/products/' +
                  _vm.category_data.img_1 +
                  ')',
              })}):_vm._e(),(_vm.category_data.img_2 != null)?_c('div',{staticClass:"swiper-slide",style:({
                'background-image':
                  'url(/assets/fotos_catalogo/products/' +
                  _vm.category_data.img_2 +
                  ')',
              })}):_vm._e(),(_vm.category_data.img_3 != null)?_c('div',{staticClass:"swiper-slide",style:({
                'background-image':
                  'url(/assets/fotos_catalogo/products/' +
                  _vm.category_data.img_3 +
                  ')',
              })}):_vm._e(),(_vm.category_data.img_1 == null)?_c('div',{staticClass:"swiper-slide",style:({ 'background-image': 'url(' + _vm.imgProd + ')' })}):_vm._e()]),_c('div',{staticClass:"slider-swiper-button-next"})])])])],1),_c('section',{staticClass:"seccao header-fixo",attrs:{"id":"feed"}},[_c('main',{staticClass:"listagem-produtos-com-footer"},[_c('div',{staticClass:"main-inner-wrapper"},[_c('div',{staticClass:"main-coluna-100p"},[_c('h2',{staticClass:"title-with-strap"},[_vm._v(_vm._s(_vm.category_data.name))]),_c('div',{staticClass:"produtos-outer-wrapper swiper-container-a"},[_c('div',{staticClass:"produtos-wrapper swiper-wrapper"},[_vm._l((_vm.feed_families),function(item){return _c('div',{key:item.id,staticClass:"produto-item swiper-slide bot-items"},[_c('router-link',{key:'/familia/' + item.id,staticClass:"links",attrs:{"to":{
                    path:
                      '/familia/' +
                      item.id +
                      '-' +
                      item.name.replaceAll(' ', '-').toLowerCase(),
                  }}},[_c('div',{staticClass:"produto-item-inner-wrapper"},[(item.image == '')?_c('div',{staticClass:"imagem-wrapper",style:({ 'background-image': 'url(' + _vm.imgProd + ')' })}):_vm._e(),(item.image != '')?_c('div',{staticClass:"imagem-wrapper",style:({
                        'background-image':
                          'url(/assets/fotos_catalogo/products/' +
                          item.image +
                          ')',
                      })}):_vm._e(),_c('div',{staticClass:"conteudo-wrapper"},[_c('h4',{staticClass:"produto-nome"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"produto-subtitulo font-size-16"},[_vm._v(" "+_vm._s(item.feed_description)+" ")]),(item.price > 0 && _vm.isLogged)?_c('div',{staticClass:"produto-preco",staticStyle:{"line-height":"23px"}},[_c('span',{staticStyle:{"font-weight":"normal"}},[_vm._v("desde")]),(_vm.isLogged)?_c('div',{staticStyle:{"color":"var(--cor-principal)"}},[_vm._v(" "+_vm._s(parseInt(item.price) .toFixed(0) .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1."))+"€ ")]):_vm._e()]):_vm._e(),(!_vm.isLogged)?_c('div',{staticClass:"produto-preco"},[_c('span',[_vm._v("Preço sob consulta")])]):_vm._e()])])])],1)}),_vm._l((_vm.feed_products),function(item){return _c('div',{key:item.id,staticClass:"produto-item swiper-slide bot-items"},[_c('router-link',{key:'/produto/' + item.id,staticClass:"links",attrs:{"to":{
                    path:
                      '/produto/' +
                      item.id +
                      '-' +
                      item.name.replaceAll(' ', '-').toLowerCase(),
                  }}},[_c('div',{staticClass:"produto-item-inner-wrapper"},[(item.image == '')?_c('div',{staticClass:"imagem-wrapper",style:({ 'background-image': 'url(' + _vm.imgProd + ')' })}):_vm._e(),(item.image != '')?_c('div',{staticClass:"imagem-wrapper",style:({
                        'background-image':
                          'url(/assets/fotos_catalogo/products/' +
                          item.image +
                          ')',
                      })}):_vm._e(),_c('div',{staticClass:"conteudo-wrapper"},[_c('h4',{staticClass:"produto-nome"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"produto-subtitulo font-size-16"},[_vm._v(" "+_vm._s(item.feed_description)+" ")]),(
                          item.discount_price > 0 && _vm.isLogged != undefined
                        )?_c('div',{staticClass:"produto-preco",staticStyle:{"line-height":"23px"}},[_c('span',{staticStyle:{"font-weight":"lighter","text-decoration":"line-through","color":"#666"}},[_vm._v(_vm._s(parseInt(item.price) .toFixed(0) .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1."))+"€")]),_c('div',{staticStyle:{"color":"var(--cor-2)"}},[_vm._v(" "+_vm._s(parseInt(item.discount_price) .toFixed(0) .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1."))+"€ ")])]):(item.price > 0 && _vm.isLogged != undefined)?_c('div',{staticClass:"produto-preco",staticStyle:{"line-height":"23px"}},[_c('span',{staticStyle:{"font-weight":"normal"}},[_vm._v("desde")]),_c('div',{staticStyle:{"color":"var(--cor-principal)"}},[_vm._v(" "+_vm._s(parseInt(item.price) .toFixed(0) .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1."))+"€ ")])]):_c('div',{staticClass:"produto-preco"},[_c('span',[_vm._v("Preço sob consulta")])])])])])],1)})],2)]),(_vm.createSlider)?_c('div',{staticClass:"produtos-swiper-button-prev"}):_vm._e(),(_vm.createSlider)?_c('div',{staticClass:"produtos-swiper-button-next"}):_vm._e()])])]),_c('Footer')],1),_c('div',{staticClass:"loadingLayer",style:('display: ' + _vm.loadingStyle)},[_c('img',{attrs:{"src":"/assets/imagens/loading.gif"}}),_c('h3',[_vm._v("A carregar...")])])])}
var staticRenderFns = []

export { render, staticRenderFns }